//Libraries
import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Components
import SearchResults from '../components/search/searchResults'
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'

const Search: FunctionComponent<{}> = () => {
  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Search</title>
          <link rel="canonical" href="https://ooa.csi.edu/search/" />
          <meta
            name="description"
            content="The search page for the Office on Aging Website."
          />
        </Helmet>

      <Header />

      <Layout>
        <SearchResults />
      </Layout>

      <Footer />
    </FontSizeProvider>
  )
}

export default Search
