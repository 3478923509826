//Libraries
import React, { FunctionComponent } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

//Styles
import styles from './css/search.module.scss'

const SearchResults: FunctionComponent<{}> = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <script
          async
          src="https://cse.google.com/cse.js?cx=2acda01ef4cb164d7"
        ></script>
      </Helmet>

      <div className={styles.results}>
        <h2 className={styles.title}>Site Search</h2>
        <div className={'gcse-search'}></div>
      </div>
    </HelmetProvider>
  )
}

export default SearchResults
